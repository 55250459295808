.navigation {
  background-color: #333;
  padding: 1rem;
}

.navigation ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin: 0;
}

.navigation a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.navigation a:hover {
  text-decoration: underline;
}

.navigation a.active {
  color: black;
}

.navigation-wrapper {
  display: flex;
  justify-content: center;
}

.glassmorphism-nav {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
  margin: 10px;
  width: 32%;
}

@media (max-width: 640px) {
  .glassmorphism-nav {
    width: 100%;
  }
}

.glassmorphism-nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.glassmorphism-nav li {
  display: inline;
}

.glassmorphism-nav a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.glassmorphism-nav a:hover {
  color: #ddd;
}

.glassmorphism-nav a.active {
  color: black;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mobile-menu-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.mobile-menu-overlay li {
  margin: 1rem 0;
}

.mobile-menu-overlay a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.mobile-menu-overlay a:hover {
  color: #ddd;
}